<template>
	<b-container fluid id="panel">
		<div class="px-4 mb-4">
			<b-card-title>{{ $t('Navbar.Administration') }}</b-card-title>
			<b-card-text class="mx-3 mb-4">{{ $t('Administration.Hint') }}</b-card-text>
			<b-card-group deck>
				<router-link v-for="(feature, index) in features" :key="index" :to="'/administration/'+ feature.url" 
					v-slot="{href, route, navigate}" v-show="feature.url!=''">
					<b-card class="mb-4" :footer="$t('General.View', {param: $t('Navbar.'+feature.name)})" footer-tag="footer" @click="navigate"
						:title="$t('Navbar.' + feature.name)" v-if="$checkPermission(feature.permission)">
						<b-card-text>{{ $t('Administration.' + feature.longDescription) }}</b-card-text>
						<font-awesome-icon :icon="['fas', feature.icon]" class="icon" />
					</b-card>
				</router-link>
			</b-card-group>
		</div>
	</b-container>
</template>

<script>

export default {
	name: "cg-panel",
	data: function () {
		return {
			features: [
				{ name: 'Accounts', longDescription: 'AccountDescription', permission: 'list-account', icon: 'user', url: 'account' },
				{ name: 'Company', longDescription: 'CompanyDescription', permission: 'view-company', icon: 'building', url: 'company' },
				// {name: 'Help', longDescription: 'HelpDescription', permission: '', icon: 'hands-helping', url: 'help'},
				{ name: 'LandingPage', longDescription: 'LandingPageDescription', permission: 'view-landing-page', icon: 'paper-plane', url: 'landingpage' },
				{ name: 'Roles', longDescription: 'RolesDescription', permission: 'list-role', icon: 'users-cog', url: 'roles' },
				{ name: 'Targets', longDescription: 'TargetDescription', permission: 'view-target', icon: 'users', url: 'targets' },
				// {name: 'Report', longDescription: 'ReportDescription', permission: '', icon: 'chart-line', url: 'report'},
				{ name: 'Template', longDescription: 'TemplateDescription', permission: 'list-template', icon: 'th-list', url: 'template' },
				{ name: 'Attachment', longDescription: 'AttachmentDescription', permission: 'modify-template', icon: 'paperclip', url: 'attachment' },
			]
		}
	}
};
</script>

<style lang="less">
#panel {
	.card-title {
		font-size: 1.3em;
	}
	.card-deck .card {
		cursor: pointer;
		flex: 1 0 30%;
		max-width: calc((100% / 3) - 30px);
		&:hover {
			box-shadow: 0px 0px 7px #dedede;
		}
	}
	.card-body {
		justify-content: space-between;
		flex-direction: column;
		display: flex;
	}
	.card-footer {
		text-align: right;
	}
	.icon {
		width: 100%;
		height: 70px;
		margin-top: 20px;
	}
}
@media only screen and (max-width: 991px) {
	#panel .card-deck .card {
		flex: 1 0 100%;
		max-width: 100%;
	}
}
</style>